import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useBiLogger } from '@common/providers';
import { ItemContainer, ItemHeader, ItemContent } from './elements';
import { getAvailableActions } from './CommentsListItem.utils';
import { classes, st } from './CommentsListItem.st.css';
import { ThreeDotsLoader as ThreeDotsLoaderComponent } from 'wix-ui-tpa';
import { CommentType } from '@common/store/member-slice/member-comments-slice';

interface CommentsListItemProps {
  isRTL?: boolean;
  isMobile?: boolean;
  disableActions?: boolean;
  data: CommentType;
  handleOpenItem?: (comment: CommentType) => void;
  handleDeleteItem?: (comment: CommentType) => void;
  handleCopyItemLink?: (commentUrl: string) => void;
}

const CommentsListItem: FC<CommentsListItemProps> = ({
  isRTL,
  isMobile,
  disableActions,
  data,
  handleOpenItem,
  handleDeleteItem,
  handleCopyItemLink,
}) => {
  const { t } = useTranslation();
  const { logEvent } = useBiLogger();

  const availableActions = getAvailableActions(
    data,
    {
      handleCopyItemLink,
      handleDeleteItem,
    },
    t,
  );

  const COMMON_BI_DATA = {
    type: 'comment',
    post_id: data.id,
    parent_post_id: data.post.id,
  };

  return (
    <ItemContainer isMobile={isMobile}>
      {data.status === 'PENDING' && (
        <div className={st(classes.loaderContainer)}>
          <ThreeDotsLoaderComponent />
        </div>
      )}
      <ItemHeader isMobile={isMobile} data={data} />

      <ItemContent
        isRTL={isRTL}
        isMobile={isMobile}
        data={data}
        disableActions={disableActions}
        availableActions={availableActions}
        handleLogEvent={(biData: any) => {
          logEvent({
            ...COMMON_BI_DATA,
            ...biData,
          });
        }}
        handleOpenItem={() => {
          if (handleOpenItem) {
            logEvent({
              ...COMMON_BI_DATA,
              evid: 516,
            });
            handleOpenItem(data);
          }
        }}
      />
    </ItemContainer>
  );
};

export default CommentsListItem;
