import React, { FC, Fragment } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  Badge as BadgeTPA,
  BadgePriority,
  Image,
  ImageResizeOptions,
} from 'wix-ui-tpa';
import { Interaction } from '@wix/ambassador-forum-v1-post/types';

import {
  ActionsMenu,
  ActionsMenuItemProps,
  Badge,
  StatsBar,
} from '@common/components';
import { classes, st } from './ItemContent.st.css';
import { CommentType } from '@common/store/member-slice/member-comments-slice';

const getStatsByInteraction = (
  interaction: Interaction,
  stats: { likes?: number; replies?: number; score?: number },
  isReply: boolean,
) => {
  switch (interaction) {
    case Interaction.REACTION:
      return isReply
        ? {
            likes: stats.likes,
          }
        : {
            likes: stats.likes,
            replies: stats.replies,
          };
    case Interaction.VOTE:
      return isReply
        ? {
            likes: stats.likes,
          }
        : {
            score: stats.score,
            replies: stats.replies,
          };
    case Interaction.NONE:
      return isReply
        ? {}
        : {
            replies: stats.replies,
          };
    default:
      return stats;
  }
};

interface ItemContentProps {
  isRTL?: boolean;
  isMobile?: boolean;
  data: CommentType;
  availableActions: ActionsMenuItemProps[];
  disableActions?: boolean;
  handleOpenItem: () => void;
  handleLogEvent: (eventData: { [key: string]: any }) => void;
}

const ItemContent: FC<ItemContentProps> = ({
  isRTL = false,
  isMobile = false,
  disableActions,
  data,
  availableActions,
  handleOpenItem,
  handleLogEvent,
}) => {
  const { t } = useTranslation();

  const { owner, createdDate } = data;

  const commentMedia = data.contentMedia ? (
    <Image
      alt={data.contentMedia.alt}
      src={data.contentMedia.src}
      fluid
      width={580}
      height={268}
      resize={ImageResizeOptions.cover}
    />
  ) : null;

  const badgeToRender =
    owner.badges.length > 0 ? (
      <Badge
        className={st(classes.ownerBadgeContainer)}
        icon={owner.badges[0].icon}
        title={owner.badges[0].title!}
      />
    ) : null;

  return (
    <Fragment>
      <div
        className={st(classes.contentWrapper, { mobile: isMobile, rtl: isRTL })}
      >
        <div className={st(classes.headerContainer)}>
          <div className={st(classes.headerContent)}>
            <div className={st(classes.itemHeaderOwner)}>
              <div className={st(classes.itemHeaderOwnerName)}>
                {owner.name}
              </div>
              {badgeToRender}
            </div>
            <div className={st(classes.headerCreatedDate)}>
              {t('fullDate', { time: new Date(createdDate) })}
            </div>
          </div>

          <div>
            <ActionsMenu
              disabled={disableActions}
              buttonClassName={classes.actionButton}
              isMobile={isMobile}
              actions={availableActions}
              onAfterMenuOpen={(clickHandler) => {
                clickHandler();

                handleLogEvent({
                  evid: 524,
                });
              }}
              onAfterMenuItemClick={(clickHandler, itemBiData) => {
                clickHandler();

                if (itemBiData) {
                  handleLogEvent(itemBiData);
                }
              }}
            />
          </div>
        </div>

        {data.isBestAnswer && (
          <div className={st(classes.bestAnswerBadgeContainer)}>
            <BadgeTPA priority={BadgePriority.primary}>
              {t('comment-marked-type.best-answer')}
            </BadgeTPA>
          </div>
        )}

        {data.url ? (
          <a
            data-hook="comments-list-item-link"
            href={data.url}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleOpenItem();
            }}
            className={st(classes.contentContainer, classes.link)}
          >
            <div className={st(classes.itemContent)}>{data.content}</div>

            {commentMedia && (
              <div
                className={st(classes.itemContentMedia, { mobile: isMobile })}
              >
                {commentMedia}
              </div>
            )}
          </a>
        ) : (
          <div className={st(classes.contentContainer)}>
            <div className={st(classes.itemContent)}>{data.content}</div>

            {commentMedia && (
              <div
                className={st(classes.itemContentMedia, { mobile: isMobile })}
              >
                {commentMedia}
              </div>
            )}
          </div>
        )}

        <div className={st(classes.statsContainer)}>
          <StatsBar
            isRTL={isRTL}
            {...getStatsByInteraction(
              data.post.commentInteraction,
              data.stats,
              data.isReply,
            )}
            likesIconType={
              data.post.commentInteraction === Interaction.REACTION
                ? 'emotions'
                : 'none'
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ItemContent;
