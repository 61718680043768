import React, { FC, useState } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { ToastSkin } from 'wix-ui-tpa';

import {
  Status,
  getMemberComments,
  CommentType,
  hasPendingComments,
} from '@common/store/member-slice/member-comments-slice';
import { scrollToTarget, copyTextToClipboard } from '@common/utils';
import { useBiLogger } from '@common/providers';
import { useModal, useToast } from '@common/hooks';
import {
  WidgetContainer,
  Pagination,
  ThreeDotsLoader,
  ConfirmDeleteModal,
  ErrorMessage,
} from '@common/components';
import { ControllerProps } from '../../controller';
import { CommentsList } from '../containers';

const COMMENTS_WIDGET_CONTAINER_DATA_HOOK = 'comments-widget-container';

const MemberComments: FC<ControllerProps> = ({ state, actions }) => {
  const { t } = useTranslation();
  const { logEvent } = useBiLogger();
  const { isRTL, isMobile } = useEnvironment();
  const [activeComment, setActiveComment] = useState<CommentType | undefined>();

  const { showToast, Toast } = useToast();
  const {
    showModal: showConfirmDeleteModal,
    Modal: ConfirmDeleteCommentModal,
  } = useModal(ConfirmDeleteModal, {
    onClose: () => {
      // @TODO maybe add BI events here and onOpen
      setActiveComment(undefined);
    },
  });

  const pageTitle = t('profile-forum-comments.forum-comments');
  const memberComments = getMemberComments(state);
  const disableActions = hasPendingComments(state);

  switch (memberComments.status) {
    case Status.INITIAL:
      return (
        <WidgetContainer
          dataHook={COMMENTS_WIDGET_CONTAINER_DATA_HOOK}
          isMobile={isMobile}
          title={pageTitle}
        >
          <ThreeDotsLoader />
        </WidgetContainer>
      );

    case Status.READY:
    case Status.PENDING:
      const { status, comments, pagination } = memberComments;
      const isLoading = status === Status.PENDING;

      return (
        <WidgetContainer
          dataHook={COMMENTS_WIDGET_CONTAINER_DATA_HOOK}
          isRTL={isRTL}
          isMobile={isMobile}
          title={pageTitle}
        >
          <Toast />
          <ConfirmDeleteCommentModal
            title={t('delete-comment-modal.delete-comment')}
            subTitle={t('delete-comment-modal.are-you-sure')}
            handleConfirm={async () => {
              if (activeComment) {
                const isLastItemOnPage =
                  comments.length === 1 && comments[0].id === activeComment.id;

                await actions.deleteMemberComment(
                  activeComment,
                  isLastItemOnPage,
                );

                logEvent({
                  evid: 555,
                  type: 'comment',
                  post_id: activeComment?.id,
                });
              }
            }}
          />

          <CommentsList
            isRTL={isRTL}
            isMobile={isMobile}
            isLoading={isLoading}
            comments={comments}
            disableActions={disableActions}
            handleDeleteItem={(commentData: CommentType) => {
              setActiveComment(commentData);
              showConfirmDeleteModal();
            }}
            handleCopyItemLink={async (commentUrl: string) => {
              if (commentUrl) {
                if (await copyTextToClipboard(commentUrl)) {
                  showToast({ message: t('share-link.link-copied'), isMobile });
                } else {
                  showToast({
                    message: t('share-link.link-copied-failure'),
                    isMobile,
                    skin: ToastSkin.error,
                  });
                }
              }
            }}
            handleOpenItem={(comment: CommentType) => {
              if (comment) {
                actions.navigateToComment(comment);
              }
            }}
          />

          {!isLoading && pagination.pageCount > 1 && (
            <Pagination
              isMobile={isMobile}
              config={pagination}
              handlePageChange={actions.fetchMemberComments}
              handleOnBeforePageChange={(nextPage) => {
                scrollToTarget(COMMENTS_WIDGET_CONTAINER_DATA_HOOK);
                logEvent({
                  evid: 519,
                  page_destination: String(nextPage),
                });
              }}
            />
          )}
        </WidgetContainer>
      );

    case Status.ERROR:
      return <ErrorMessage />;

    default:
      throw new Error('Unreachable');
  }
};

export default MemberComments;
